import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import { listReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"

const StyledList = styled(motion.ul)`
  ${listReset};

  display: flex;
  /* flex-wrap: wrap;
  justify-content: center; */
  margin: ${rem(-24)};
  margin-top: ${rem(60)};
  margin-bottom: ${rem(30)};

  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 ${rem(24)};
  scroll-snap-type: x mandatory;

  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    min-width: calc(100% - ${rem(26 * 2)});

    /* &:last-child {
      margin-left: ${rem(24)};
      margin-right: auto;
    } */

    padding: ${rem(20)} ${rem(26)};
    box-sizing: content-box;

    img {
      width: ${rem(58)};
      height: ${rem(58)};
    }

    strong {
      color: ${({ theme }) => theme.button.background.active};
      display: inline-block;
      font-size: ${rem(16)};
      margin-top: ${rem(8)};
      width: 100%;
    }

    span {
      color: ${({ theme }) => theme.button.text.default};
      display: inline-block;
      font-size: ${rem(16)};
      line-height: 150%;
      margin-top: ${rem(8)};
    }
  }

  @media (min-width: ${rem(theme.screens.custom.mobile)}) {
    justify-content: flex-start;
    
    > li {
      max-width: ${rem(240)};
      width: auto;
      min-width: ${rem(180)};
    }
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    /* > li {
      margin: 0;

      &:last-child {
        margin-left: 0;
      }
    } */

    flex-wrap: wrap;
    overflow-x: auto;

    margin-bottom: ${rem(-20)};

    > li {
      text-align: left;
    }
  }
`

const StyledItem = styled(motion.li)``

const itemVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: ({ index }) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index,
      type: "spring",
      damping: 25,
      stiffness: 150 + 50 * index,
    },
  }),
}

// const functionItemSpringTransition = {
//   type: "spring",
//   damping: 100,
//   stiffness: 250,
// }

// FIXME: Stagger animācija ar 'delay' nenostrādā

export default function IconsList({ items }) {
  return (
    <StyledList>
      {items.map(({ icon, iconSharp, title, description }, index) => (
        <StyledItem
          key={`icon_${index + 1}`}
          initial="initial"
          animate="enter"
          variants={itemVariants}
          custom={{ index }}
        >
          {iconSharp && iconSharp.childImageSharp ? (
            <Img fixed={iconSharp.childImageSharp.fixed} />
          ) : (
            <img src={icon.url} alt="" />
          )}

          {title && <strong>{title}</strong>}
          {description && <span>{description}</span>}
        </StyledItem>
      ))}
    </StyledList>
  )
}
