import React from "react"
import Img from "gatsby-image"
import styled, { css } from "styled-components"
import debounce from "lodash.debounce"
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
  useDragControls,
  useSpring,
} from "framer-motion"
import { rem } from "./utils"
import theme from "./theme"

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  border-radius: ${rem(7)};
  margin-top: ${rem(30)};
  max-width: ${rem(400)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    max-width: ${rem(580)};
  }

  @media (min-width: ${rem(theme.screens.md)}) {
    margin-top: 0;
  }
`

const ImageGroup = styled.div`
  border-top-left-radius: ${rem(7)};
  border-top-right-radius: ${rem(7)};
  display: flex;
  height: ${rem(240)};
  overflow: hidden;

  img {
    will-change: opacity;
  }

  > * {
    width: 100%;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    height: ${rem(380)};
  }
`

const ContentGroup = styled.div`
  min-height: ${rem(170)};
  padding: ${rem(16)} ${rem(32)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(32)} ${rem(40)};
  }
`

const Title = styled.strong`
  color: ${({ theme }) => theme.text.heading};
  display: block;
  font-size: ${rem(20)};
  margin-bottom: 0.5em;
`

const Description = styled.p`
  color: ${({ theme }) => theme.text.body};
  line-height: 150%;
  margin: 0;
`

const Slide = styled(motion.div)``

const SliderGroup = styled.div`
  padding: 0 ${rem(32)} ${rem(24)};
`

const StyledDragger = styled(motion.div)`
  cursor: grabbing;
  position: absolute;
  top: 7px;

  > svg {
    display: block;
  }
`

function Dragger(props) {
  return (
    <StyledDragger {...props}>
      <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="32"
          y="32"
          width="32"
          height="32"
          rx="5"
          transform="rotate(180 32 32)"
          fill="#1D1D1D"
        />
        <path
          d="M13 11l-5 5 5 5M19 21l5-5-5-5"
          stroke="#BABABA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledDragger>
  )
}

const SliderWrapper = styled.div`
  height: ${rem(48)};
  position: relative;
`

const LineWrapper = styled.div`
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-1px);
`

const Line = styled(motion.div)`
  background-color: #969696;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: 0% 0%;

  ${({ progress }) =>
    progress &&
    css`
      background-color: #c8102e;
    `};
`

const SnapPoint = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  width: 6px;
  height: 10px;
  transform: translate(-50%, -50%);
  background-color: #969696;
  border-radius: 1px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${theme.colors.accent};
    `};
`

function SnapPoints({ active, total, sliderWidth }) {
  const t = React.useMemo(() => Array.from({ length: total }, (_, i) => i), [
    total,
  ])

  // const offset = sliderWidth / (t.length + 1)
  const offsetPercent = (1 / (t.length - 1)) * 100

  return (
    <div>
      {/* <SnapPoint key="start" style={{ left: `${3}px` }} /> */}
      {t.map(i => (
        <SnapPoint
          key={`middle_${i}`}
          active={i <= active}
          style={{ left: `${offsetPercent * i}%` }}
        />
      ))}
      {/* <SnapPoint key="end" style={{ left: `${sliderWidth - 3}px` }} /> */}
    </div>
  )
}

// https://codesandbox.io/s/framer-motion-usedragcontrols-rql7b

function Slider({ total, onChange }) {
  const handleX = useMotionValue(0)
  const dragControls = useDragControls()
  const [sliderWidth, setSliderWidth] = React.useState(0)
  const [mouseDown, setMouseDown] = React.useState(false)
  const handleXSpring = useSpring(handleX, { damping: 50, stiffness: 800 })
  const progressScaleX = useTransform(
    handleXSpring,
    [0, sliderWidth - 16],
    [0, 1]
  )

  const wrapperRef = React.useRef()
  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      wrapperRef.current = node
      const { width } = node.getBoundingClientRect()
      setSliderWidth(width)
    }
  }, [])

  const snapOffset = sliderWidth / (total - 1)

  const [snapIndex, setSnapIndex] = React.useState(0)

  React.useEffect(() => {
    onChange && onChange(snapIndex)
    handleXSpring.set(snapOffset * snapIndex - 16)
  }, [snapIndex, handleXSpring, snapOffset, onChange])

  React.useEffect(() => {
    function updateMeasurements() {
      const { width } = wrapperRef.current.getBoundingClientRect()
      setSliderWidth(width)
    }

    const onResizeThrottled = debounce(updateMeasurements, 250)

    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResizeThrottled)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", onResizeThrottled)
      }
    }
  }, [])

  React.useEffect(() => {
    function handlePointerUp() {
      setMouseDown(false)

      let snapValue = handleXSpring.get() / snapOffset
      snapValue = Math.round(snapValue / 0.25) * 0.25
      snapValue = Math.round(snapValue)
      if (snapValue < 0) {
        snapValue = 0
      } else if (snapValue > total - 1) {
        snapValue = total - 1
      }

      handleXSpring.set(snapOffset * snapValue - 16)
      setSnapIndex(snapValue)
    }

    if (mouseDown) {
      window.addEventListener("pointerup", handlePointerUp)

      return () => {
        window.removeEventListener("pointerup", handlePointerUp)
      }
    }
  }, [mouseDown, handleXSpring, snapOffset, total])

  return (
    <SliderWrapper
      ref={measuredRef}
      onPointerDown={e => {
        dragControls.start(e, { snapToCursor: true })
        setMouseDown(true)
      }}
    >
      <LineWrapper>
        <Line />
        <Line progress style={{ scaleX: progressScaleX }} />
      </LineWrapper>
      <SnapPoints active={snapIndex} total={total} sliderWidth={sliderWidth} />
      <Dragger
        drag="x"
        dragConstraints={{
          left: 0,
          right: sliderWidth - 32,
        }}
        dragControls={dragControls}
        dragElastic={0.2}
        dragMomentum={false}
        dragTransition={{ bounceDamping: 300, bounceStiffness: 1200 }}
        style={{ x: handleXSpring }}
      />
    </SliderWrapper>
  )
}

const slideVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export default function ContentSlider({
  image,
  imageSharp,
  title,
  description,
  index,
  total,
  onChange,
}) {
  return (
    <Wrapper>
      <AnimatePresence exitBeforeEnter>
        <Slide
          key={`${title}_${index + 1}`}
          initial="initial"
          animate="enter"
          exit="exit"
          variants={slideVariants}
        >
          <ImageGroup>
            {imageSharp && imageSharp.childImageSharp ? (
              <Img
                alt={image.alt || ""}
                fluid={imageSharp.childImageSharp.fluid}
              />
            ) : image ? (
              <img src={image.url} alt={image.alt || ""} />
            ) : null}
          </ImageGroup>

          <ContentGroup>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </ContentGroup>
        </Slide>
      </AnimatePresence>

      <SliderGroup>
        <Slider total={total} onChange={onChange} />
      </SliderGroup>
    </Wrapper>
  )
}
