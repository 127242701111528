import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { listReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"
import ContactLink from "./ContactLink"

// const imageSmallSize = 130
// const imageRegularSize = 160

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  margin: ${rem(16)};
`

const ProjectTitle = styled.p`
  color: #bababa;
  font-weight: 700;
`

const StyledPersonCard = styled.div`
  flex: 1 0 auto;
  display: flex;
  /* align-items: center; */
  background-color: #1d1d1d;
  border: 1px solid #323232;
  border-radius: ${rem(5)};
`

const ImgWrapper = styled.div`
  display: none;

  @media (min-width: ${rem(560)}) {
    display: block;
    border-top-left-radius: ${rem(5)};
    border-bottom-left-radius: ${rem(5)};
    overflow: hidden;

    filter: grayscale(100%);

    width: ${rem(160)};
    min-height: ${rem(160)};

    img {
      display: block;
    }

    .gatsby-image-wrapper {
      display: block;
      width: 100% !important;
      height: 100% !important;
      min-height: ${rem(160)};
    }
  }
`

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  padding: ${rem(18)} ${rem(24)};

  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    color: ${({ theme }) => theme.text.heading};
    font-size: ${rem(16)};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  p {
    color: ${({ theme }) => theme.text.body};
    margin: 0;
  }

  ul {
    ${listReset};

    display: flex;
    flex-direction: column;
    margin-top: ${rem(16)};

    > li + li {
      margin-top: ${rem(8)};
    }

    a {
      color: ${({ theme }) => theme.text.body};
      text-decoration: none;

      &:hover {
        color: ${theme.colors.accent};
      }
    }
  }
`

const LinksWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  > ul {
    margin-right: ${rem(32)};
  }
`

export default function PersonCard({ data }) {
  const { full_name, position, project, picture, pictureSharp, contacts } = data
  return (
    <Wrapper>
      <ProjectTitle>{project}</ProjectTitle>

      <StyledPersonCard>
        <ImgWrapper>
          {pictureSharp && pictureSharp.childImageSharp ? (
            <Img fixed={pictureSharp.childImageSharp.fixed} />
          ) : picture ? (
            <img src={picture.url} alt="" />
          ) : null}
        </ImgWrapper>

        <ContentWrapper>
          <h4>{full_name}</h4>
          <p
            style={{
              lineHeight: 1.5,
              maxWidth: "30ch",
              whiteSpace: "pre-line",
            }}
          >
            {position.split(" / ").join("\n")}
          </p>
          <LinksWrapper>
            <ul>
              {contacts
                .filter(contact => contact.type !== "LinkedIn")
                .map(contact => {
                  const { type, text, link } = contact
                  return (
                    <li key={link.url}>
                      <a href={link.url} target="_self" title={type}>
                        {text || link}
                      </a>
                    </li>
                  )
                })}
            </ul>
            {contacts
              .filter(contact => contact.type === "LinkedIn")
              .map(contact => {
                const { link } = contact
                return (
                  <ContactLink key={link.url} type="LinkedIn" link={link} />
                )
              })}
          </LinksWrapper>
        </ContentWrapper>
      </StyledPersonCard>
    </Wrapper>
  )
}
