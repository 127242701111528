import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { listReset } from "../reset"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import PersonCard from "../PersonCard"
// import ContactForm from "../ContactForm"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_Contacts_section = graphql`
  fragment FRAGMENT_Contacts_section on PRISMIC_Contacts_section {
    _meta {
      uid
    }

    content
    body {
      ... on PRISMIC_Contacts_sectionBodyContact_person {
        type

        primary {
          picture
          pictureSharp {
            childImageSharp {
              fixed(quality: 90, width: 320, height: 320) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
            publicURL
          }
          full_name
          position
          project
        }

        fields {
          type
          text
          link {
            ... on PRISMIC__ExternalLink {
              url
            }
          }
        }
      }
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(60)} ${rem(24)} ${rem(96)};
  }
`

const FlexGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(48)};
  margin-bottom: ${rem(48)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;
    margin-top: ${rem(56)};
    margin-bottom: ${rem(24)};
  }
`

const ContactsList = styled.ul`
  ${listReset};

  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  margin: ${rem(-16)};
  width: 100%;

  @media (min-width: ${rem(theme.screens.md)}) {
    flex-direction: row;
    flex-wrap: wrap;

    > li {
      flex: 0 1 calc(50% - ${rem(32)});
      max-width: calc(50% - ${rem(32)});
    }
  }
`

// const FormWrapper = styled.div`
//   flex: 1 1 auto;
//   margin-top: ${rem(48)};
//   max-width: ${rem(530)};

//   @media (min-width: ${rem(theme.screens.sm)}) {
//     margin-top: 0;
//     margin-left: ${rem(96)};
//   }
// `

export default function ContactsSection({ data }) {
  const { content, body } = data

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <RichText render={content} />

      <motion.div
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <FlexGroup>
          <ContactsList>
            {body
              // FIXME: Pagaidām tikai rādām ar bildēm
              .filter(p => p.primary.picture)
              .map(contactPerson => {
                const { primary, fields } = contactPerson
                const data = { ...primary, contacts: fields }
                return <PersonCard key={primary.full_name} data={data} />
              })}
          </ContactsList>

          {/* <FormWrapper>
            <ContactForm />
          </FormWrapper> */}
        </FlexGroup>
      </motion.div>
    </SectionLayout>
  )
}
