import React from "react"
import styled, { css } from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { RichText as PrismicRichText } from "prismic-reactjs"
import { rem } from "./utils"
import theme from "./theme"
import ExternalLink from "./ExternalLink"

const Date = styled.time`
  color: ${theme.colors.variants.dark.text.heading};
  font-size: ${rem(16)};
  margin-bottom: ${rem(6)};
  width: ${rem(120)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-bottom: 0;
  }
`

const Title = styled.h5`
  color: ${theme.colors.variants.dark.text.heading};
  font-size: ${rem(16)};
  margin-top: 0;
  margin-bottom: 0;
`

const Content = styled.div`
  color: #fff;
  padding: ${rem(12)} ${rem(24)} ${rem(19)};

  p {
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0;
  }

  p + p {
    margin-top: 1em;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(12)} ${rem(80)} ${rem(24)} ${rem(144)};
  }
`

const SectionWrapper = styled(motion.section)`
  border: 1px solid #323232;

  &:first-of-type {
    border-top-left-radius: ${rem(5)};
    border-top-right-radius: ${rem(5)};
  }

  &:last-of-type {
    border-bottom-left-radius: ${rem(5)};
    border-bottom-right-radius: ${rem(5)};
  }

  &:not(:last-of-type) {
    border-bottom-width: 0;
  }

  ${({ open }) =>
    open &&
    css`
      border-top-color: #686868;
    `};
`

const SectionHeader = styled(motion.header)`
  color: #bababa;
  cursor: pointer;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${rem(16)} ${rem(24)};

  &:hover {
    background-color: #1d1d1d;
  }

  ${({ open }) =>
    open &&
    css`
      color: #ffffff;

      /* &:hover {
        background-color: #1d1d1d;
      } */
    `};

  @media (min-width: ${rem(theme.screens.sm)}) {
    align-items: center;
  }
`

const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;
    align-items: center;
  }
`

const LinkWrapper = styled.div`
  margin-top: ${rem(12)};
`

const Toggle = styled.div`
  color: currentColor;
  padding: ${rem(6)};
  transform: translateX(${rem(6)});

  ${({ open }) =>
    open &&
    css`
      > svg {
        transform: scaleY(-1);
      }
    `};
`

// const sectionVariants = {
//   initial: {
//     backgroundColor: "#1D1D1D",
//   },
//   expanded: {
//     backgroundColor: "#323232",
//   },
// }

const headerVariants = {
  initial: {
    color: "#BABABA",
  },
  expanded: {
    color: "#FFFFFF",
  },
}

export default function Accordion({ data }) {
  const [expanded, setExpanded] = React.useState(false)

  // React.useEffect(() => {
  //   const widerThanSmallScreen =
  //     typeof window !== "undefined" &&
  //     window.matchMedia(`(min-width: ${rem(theme.screens.sm)})`)
  //   if (widerThanSmallScreen) {
  //     setExpanded(true)
  //   }
  // }, [])

  const { title, date, content, link, link_label } = data

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <SectionWrapper
    // initial="initial"
    // animate={expanded ? "expanded" : "initial"}
    // variants={sectionVariants}
    // open={expanded}
    >
      <SectionHeader
        initial="initial"
        animate={expanded ? "expanded" : "initial"}
        variants={headerVariants}
        onClick={() => setExpanded(expanded => !expanded)}
        open={expanded}
      >
        <TitleGroup>
          <Date datetime={date}>{date.replace(/-/g, ".")}</Date>
          <Title>{title}</Title>
        </TitleGroup>

        <Toggle open={expanded}>
          <svg
            width="12"
            height="6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 .5l5 5 5-5"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Toggle>
      </SectionHeader>

      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
            style={{ overflow: "hidden" }}
          >
            <Content>
              <PrismicRichText render={content} />
              <LinkWrapper>
                <ExternalLink to={link.url} label={link_label || link.url} />
              </LinkWrapper>
            </Content>
          </motion.div>
        )}
      </AnimatePresence>
    </SectionWrapper>
  )
}
