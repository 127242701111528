import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, AnimatePresence } from "framer-motion"
import { buttonReset } from "../reset"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_Solution_section = graphql`
  fragment FRAGMENT_Solution_section on PRISMIC_Solution_section {
    _meta {
      uid
    }

    content

    onepager {
      ... on PRISMIC__FileLink {
        url
      }
    }

    embed

    image
    imageSharp {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
          presentationWidth
        }
      }
      publicURL
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(48)};

  display: flex;
  flex-direction: column;

  /* IntroSection CardsList offset */
  ${({ offsetHeight }) =>
    offsetHeight &&
    css`
      padding-top: ${rem(offsetHeight - 40)} !important;
    `};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};

    /* IntroSection CardsList offset */
    ${({ offsetHeight }) =>
      offsetHeight &&
      css`
        padding-top: ${rem(offsetHeight + 40)} !important;
      `};
  }
`

const ContentGroup = styled.div``

const StyledButton = styled.a`
  ${buttonReset};
  color: ${theme.colors.accent};
  display: inline-flex;
  align-items: center;
  margin-top: ${rem(16)};
  text-decoration: none;
  transform: translateX(-1em);

  > span {
    display: inline-block;
    margin-left: 0.5em;
  }

  &:hover {
    color: ${theme.colors.variants.hover.accent};
    text-decoration: underline;
  }
`

const DownloadButton = function({ url }) {
  return (
    <StyledButton href={url} target="_blank" rel="noopener noreferrer">
      Download onepager
      <span role="img" aria-label="">
        <svg
          width="28"
          height="28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23 17v4a2 2 0 01-2 2H7a2 2 0 01-2-2v-4M9 12l5 5 5-5M14 17V5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </StyledButton>
  )
}

const MediaGroup = styled.div`
  border-radius: ${rem(4)};
  margin: ${rem(48)} 0 0;
  overflow: hidden;
`

const VideoWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;
`

// const Video = styled(motion.video)`
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `

const VideoEmbed = styled(motion.div)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > div[data-type="video"] {
    h1,
    p {
      display: none;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`

const videoVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
}

export default function SolutionSection({ data }) {
  const { content, onepager, image, imageSharp, embed } = data

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const [offsetHeight, setOffsetHeight] = React.useState(0)
  React.useLayoutEffect(() => {
    const introCards = document.body.querySelector(
      'div[data-key="intro_cards"]'
    )
    if (introCards) {
      const { height } = introCards.getBoundingClientRect()
      setOffsetHeight(height)
    }
  }, [])

  return (
    <SectionLayout offsetHeight={offsetHeight}>
      <ContentGroup>
        <RichText render={content} wide />
        {onepager && (
          <motion.div
            ref={ref}
            initial="initial"
            animate={inView ? "enter" : "initial"}
            variants={theme.motion.contentVariants}
            custom={motionDelay}
          >
            <DownloadButton url={onepager.url} />
          </motion.div>
        )}
      </ContentGroup>

      <motion.div
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <MediaGroup>
          <VideoWrapper>
            <AnimatePresence exitBeforeEnter>
              {embed ? (
                // <Video
                //   initial="initial"
                //   animate="enter"
                //   exit="exit"
                //   variants={videoVariants}
                //   controls
                //   poster={
                //     imageSharp ? imageSharp.publicURL : image && image.url
                //   }
                //   preload="metadata"
                // >
                //   <source src={video.url} type="video/mp4" />
                // </Video>
                <VideoEmbed
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={videoVariants}
                  dangerouslySetInnerHTML={{ __html: embed.html }}
                />
              ) : imageSharp && imageSharp.childImageSharp ? (
                <Img fluid={imageSharp.childImageSharp.fluid} />
              ) : image ? (
                <img src={image.url} alt="" />
              ) : null}
            </AnimatePresence>
          </VideoWrapper>
        </MediaGroup>
      </motion.div>
    </SectionLayout>
  )
}
