import React from "react"
import { graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { rem, fluidValue } from "../components/utils"
import theme from "../components/theme"
import Layout from "../components/Layout"
import Aside from "../components/Aside"
import Sentinel from "../components/Sentinel"
import TopBar from "../components/TopBar"
import MobileMenu from "../components/MobileMenu"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import IntroSection, {
  FRAGMENT_Intro_section,
} from "../components/sections/IntroSection"

import SolutionSection, {
  FRAGMENT_Solution_section,
} from "../components/sections/SolutionSection"

import ProjectSection, {
  FRAGMENT_Project_section,
} from "../components/sections/ProjectSection"

import MilestonesSection, {
  FRAGMENT_Milestones_section,
} from "../components/sections/MilestonesSection"

import PartnersSection, {
  FRAGMENT_Partners_section,
} from "../components/sections/PartnersSection"

import MapSection, {
  FRAGMENT_Map_section,
} from "../components/sections/MapSection"

import PublicationsSection, {
  FRAGMENT_Publications_section,
} from "../components/sections/PublicationsSection"

import ContactsSection, {
  FRAGMENT_Contacts_section,
} from "../components/sections/ContactsSection"

export const navigation = graphql`
  query {
    prismic {
      navigation(lang: "en-gb", uid: "navigation") {
        items {
          uid
          label
          section {
            ...FRAGMENT_Intro_section
            ...FRAGMENT_Solution_section
            ...FRAGMENT_Project_section
            ...FRAGMENT_Milestones_section
            ...FRAGMENT_Partners_section
            ...FRAGMENT_Map_section
            ...FRAGMENT_Publications_section
            ...FRAGMENT_Contacts_section
          }
        }
      }
    }
  }
`

const StyledSection = styled.section`
  background-color: ${({ theme }) => theme.background};
  position: relative;

  /* svarīgi: novērš overflow un sticky konfliktu */
  &:not([data-sticky="true"]):not([data-overflow="true"]) {
    overflow: hidden;
  }

  /* TopBar height */
  padding-top: ${rem(70)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding-top: 0;
  }
`

const asideWidth = 180
const minAsideWidth = 150

const SectionClipPath = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;

  > aside {
    display: none;
  }

  /* TopBar */
  > div {
    pointer-events: auto;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    > aside {
      display: flex;
      pointer-events: auto;
      width: ${rem(asideWidth)};
    }

    /* TopBar */
    > div {
      display: none;
    }
  }
`

const Container = styled.div`
  box-sizing: content-box;
  margin: 0 auto;
  max-width: ${rem(1080)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding-left: ${fluidValue(
      minAsideWidth,
      asideWidth + 24,
      theme.screens.sm,
      theme.screens.lg
    )};
  }

  @media (min-width: ${rem(theme.screens.lg)}) {
    padding-left: ${rem(asideWidth + 24)};
  }
`

function renderSection(uid, data) {
  switch (uid) {
    case "intro":
      return <IntroSection data={data} />

    case "solution":
      return <SolutionSection data={data} />

    case "scope":
      return <ProjectSection data={data} />

    case "project":
      return <MilestonesSection data={data} />

    case "partners":
      return <PartnersSection data={data} />

    case "map":
      return <MapSection data={data} />

    case "publications":
      return <PublicationsSection data={data} />

    case "contacts":
      return <ContactsSection data={data} />

    default:
      return <h1>{`Section: ${uid}`}</h1>
  }
}

let edgeBrowser = false
if (typeof navigator !== "undefined") {
  edgeBrowser = /edge/i.test(navigator.userAgent)
}

export default function IndexPage({ data }) {
  const items = data.prismic.navigation.items

  const [activeSection, setActiveSection] = React.useState()
  const ctaVisible = activeSection !== "intro" && activeSection !== "contacts"

  const containerRef = React.useRef()
  function navigate(uid) {
    if (typeof window !== "undefined") {
      const section = containerRef.current.querySelector(
        `section[data-key="${uid}"]`
      )
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }
    }
  }

  const topBarThemeRef = React.useRef()
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

  return (
    <Layout innerRef={containerRef}>
      <SEO />

      {items.map(({ uid, section }, index) => {
        if (section && section._meta) {
          const variant = section.theme ? section.theme.toLowerCase() : "dark"

          const sectionClipPathAttrs = {}
          const hiddenAside = index > 0
          if (hiddenAside) {
            sectionClipPathAttrs["aria-hidden"] = "true"
          }

          const sectionProps =
            uid === "intro" || uid === "map"
              ? {
                  "data-overflow": true,
                }
              : {}

          return (
            <ThemeProvider key={uid} theme={theme.colors.variants[variant]}>
              <StyledSection
                data-key={uid}
                data-theme={variant}
                {...sectionProps}
              >
                <Sentinel uid={uid} onChange={setActiveSection} />
                <Container>
                  {renderSection(section._meta.uid, section)}
                </Container>
                <Sentinel uid={uid} bottom onChange={setActiveSection} />

                <SectionClipPath {...sectionClipPathAttrs}>
                  <TopBar
                    uid={uid}
                    items={items}
                    activeSection={activeSection}
                    navigate={navigate}
                    mobileMenuOpen={mobileMenuOpen}
                    toggleMobileMenu={() => {
                      if (!mobileMenuOpen) {
                        topBarThemeRef.current = variant
                      }

                      if (typeof document !== "undefined") {
                        document.body.classList.toggle(
                          "disable-scroll",
                          !mobileMenuOpen
                        )
                      }

                      setMobileMenuOpen(!mobileMenuOpen)
                    }}
                  />

                  {(!edgeBrowser || (edgeBrowser && index === 0)) && (
                    <Aside
                      uid={uid}
                      items={items}
                      activeSection={activeSection}
                      ctaVisible={ctaVisible}
                      navigate={navigate}
                    />
                  )}
                </SectionClipPath>
              </StyledSection>
            </ThemeProvider>
          )
        }

        return null
      })}

      <ThemeProvider theme={theme.colors.variants.dark}>
        <Footer style={{ marginTop: 0 }} />
      </ThemeProvider>

      {mobileMenuOpen && (
        <ThemeProvider theme={theme.colors.variants[topBarThemeRef.current]}>
          <MobileMenu
            items={items}
            activeSection={activeSection}
            navigate={uid => {
              if (typeof document !== "undefined") {
                document.body.classList.remove("disable-scroll")
              }
              setMobileMenuOpen(false)
              navigate(uid)
            }}
          />
        </ThemeProvider>
      )}
    </Layout>
  )
}

IndexPage.fragments = [
  FRAGMENT_Intro_section,
  FRAGMENT_Solution_section,
  FRAGMENT_Project_section,
  FRAGMENT_Milestones_section,
  FRAGMENT_Partners_section,
  FRAGMENT_Map_section,
  FRAGMENT_Publications_section,
  FRAGMENT_Contacts_section,
]
