import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import ContentSlider from "../ContentSlider"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_Milestones_section = graphql`
  fragment FRAGMENT_Milestones_section on PRISMIC_Project_section {
    _meta {
      uid
    }

    content

    timeline {
      title
      description

      image
      imageSharp {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
        publicURL
      }

      project_lead_title
      project_lead_logo
      project_lead_logoSharp {
        publicURL
        childImageSharp {
          fixed(quality: 90, height: 40) {
            ...GatsbyImageSharpFixed_noBase64
            width
            height
          }
        }
      }

      # video {
      #   ... on PRISMIC__FileLink {
      #     url
      #   }
      # }
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
  }
`

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${rem(theme.screens.md)}) {
    flex-direction: row;

    > div {
      &:first-child {
        flex: 1 2 auto;
      }

      &:last-child {
        flex: 0 1 auto;
        margin-left: ${rem(60)};
      }
    }
  }
`

const LogoWrapper = styled.div`
  margin: ${rem(48)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.md)}) {
    margin: 0;
  }
`

function Logo({ title, logo, logoSharp }) {
  let width, height
  if (logoSharp && logoSharp.childImageSharp) {
    width = logoSharp.childImageSharp.fixed.width
    height = logoSharp.childImageSharp.fixed.height
  }

  return (
    <div style={{ width, height }}>
      {logoSharp && logoSharp.childImageSharp ? (
        <Img fixed={logoSharp.childImageSharp.fixed} title={title} />
      ) : (
        <img src={logo.url} alt="" title={title} />
      )}
    </div>
  )
}

function LeadLogo({ data }) {
  const {
    project_lead_title: leadTitle,
    project_lead_logo: leadLogo,
    project_lead_logoSharp: leadLogoSharp,
  } = data

  return (
    <LogoWrapper>
      <p>Project lead</p>
      {/* <AnimatePresence exitBeforeEnter>
        <motion.div
          key={leadTitle}
          initial="initial"
          animate="enter"
          exit="exit"
          variants={logoVariants}
        >
          <Logo title={leadTitle} logo={leadLogo} logoSharp={leadLogoSharp} />
        </motion.div>
      </AnimatePresence> */
      <Logo title={leadTitle} logo={leadLogo} logoSharp={leadLogoSharp} />}
    </LogoWrapper>
  )
}

const HiddenAboveMedium = styled.div`
  @media (min-width: ${rem(theme.screens.md)}) {
    display: none;
  }
`

const HiddenBelowMedium = styled.div`
  display: none;

  @media (min-width: ${rem(theme.screens.md)}) {
    display: block;
  }
`

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

// const logoVariants = {
//   initial: {
//     opacity: 0,
//   },
//   enter: {
//     opacity: 1,
//   },
//   exit: {
//     opacity: 0,
//   },
// }

export default function MilestonesSection({ data }) {
  const { content, timeline } = data

  const [activeIndex, setActiveIndex] = React.useState(0)
  const entry = timeline[activeIndex]

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <ContentGroup>
        <SpaceBetween>
          <RichText render={content} wide />

          <motion.div
            ref={ref}
            initial="initial"
            animate={inView ? "enter" : "initial"}
            variants={theme.motion.contentVariants}
            custom={motionDelay}
          >
            <HiddenBelowMedium>
              <LeadLogo data={entry} />
            </HiddenBelowMedium>
          </motion.div>
        </SpaceBetween>

        <motion.div
          ref={ref}
          initial="initial"
          animate={inView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
          custom={motionDelay}
        >
          <ContentSlider
            image={entry.image}
            imageSharp={entry.imageSharp}
            title={entry.title}
            description={entry.description}
            index={activeIndex}
            total={timeline.length}
            onChange={setActiveIndex}
          />

          <HiddenAboveMedium>
            <LeadLogo data={entry} />
          </HiddenAboveMedium>
        </motion.div>
      </ContentGroup>
    </SectionLayout>
  )
}
