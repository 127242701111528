import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "./utils"
import theme from "./theme"

const StyledCard = styled(motion.li)`
  background-color: ${theme.colors.white};
  border-radius: ${rem(7)};
  min-width: ${rem(280)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    max-width: ${rem(254)};
  }
`

const Thumbnail = styled.div`
  border-top-left-radius: ${rem(7)};
  border-top-right-radius: ${rem(7)};
  display: flex;
  height: ${rem(124)};
  overflow: hidden;

  img {
    will-change: opacity;
  }

  > * {
    width: 100%;
  }
`

const ContentGroup = styled.div`
  padding: ${rem(16)} ${rem(24)} ${rem(24)};

  h3 {
    color: ${theme.colors.variants.light.text.heading};
    font-size: ${rem(20)};
    line-height: 130%;
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  p {
    color: ${theme.colors.variants.light.text.body};
    line-height: 130%;
  }
`

const cardVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: ({ index }) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2 + 0.2 * index,
      type: "spring",
      damping: 25,
      stiffness: 150 + 50 * index,
    },
  }),
}

export default function Card({
  index,
  image,
  imageSharp,
  title,
  description,
  animate,
}) {
  return (
    <StyledCard
      initial="initial"
      animate={animate ? "enter" : "initial"}
      variants={cardVariants}
      custom={{ index }}
    >
      <Thumbnail>
        {imageSharp && imageSharp.childImageSharp ? (
          <Img alt={image.alt || ""} fluid={imageSharp.childImageSharp.fluid} />
        ) : image ? (
          <img src={image.url} alt={image.alt || ""} />
        ) : null}
      </Thumbnail>

      <ContentGroup>
        {title && <h3>{title}</h3>}
        {description && <p>{description}</p>}
      </ContentGroup>
    </StyledCard>
  )
}
