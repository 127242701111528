import React from "react"
import { graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "../utils"
import theme from "../theme"
import Accordion from "../Accordion"

export const FRAGMENT_Publications_section = graphql`
  fragment FRAGMENT_Publications_section on PRISMIC_Publications_section {
    _meta {
      uid
    }

    section_title

    publications {
      title
      date
      content
      link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      link_label
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
  }
`

const Title = styled(motion.h2)`
  color: #fff;
  font-size: ${rem(50)};
  font-weight: 300;
  margin-top: 0;
  margin-bottom: ${rem(40)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    font-size: ${rem(50)};
    margin-bottom: ${rem(60)};
  }
`

const titleVariants = {
  initial: { opacity: 0, x: 50 },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      type: "spring",
      damping: 50,
      stiffness: 150,
    },
  },
}

export default function PublicationsSection({ data }) {
  const { section_title, publications } = data

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <Title
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={titleVariants}
      >
        {section_title}
      </Title>

      <motion.div
        initial={{ opacity: 0 }}
        animate={
          inView && { opacity: 1, transition: { duration: 1, delay: 0.5 } }
        }
      >
        {publications.map((publication, index) => {
          return (
            <Accordion
              key={`publication_${publication.title}_${index + 1}`}
              data={publication}
            />
          )
        })}
      </motion.div>
    </SectionLayout>
  )
}
