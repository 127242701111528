import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { motion } from "framer-motion"
// import { listReset } from "../reset"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import IconsList from "../IconsList"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_Partners_section = graphql`
  fragment FRAGMENT_Partners_section on PRISMIC_Partners_section {
    _meta {
      uid
    }

    content

    areas {
      icon
      iconSharp {
        childImageSharp {
          fixed(quality: 90, width: 58, height: 58) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        publicURL
      }
      title
      description
    }

    partners {
      logo
      logoSharp {
        publicURL
        childImageSharp {
          fixed(quality: 90, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
            width
            height
          }
        }
      }
      title
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(48)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(120)};
  }
`

// const LogosWrapper = styled.div`
//   margin-top: ${rem(90)};

//   @media (min-width: ${rem(theme.screens.sm)}) {
//     margin-top: ${rem(120)};
//   }
// `

// const Subtitle = styled.p`
//   color: ${({ theme }) => theme.button.background.active};
//   font-weight: 700;
//   margin-bottom: ${rem(12)};
// `

// const LogosList = styled.ul`
//   ${listReset};

//   display: flex;
//   flex-wrap: wrap;
//   margin: ${rem(-24)};
// `

// const Logo = styled.li`
//   /* filter: grayscale(100%); */
//   margin: ${rem(24)};

//   /* &:hover {
//     filter: grayscale(0%);
//   } */
// `

export default function PartnersSection({ data }) {
  const {
    content,
    areas,
    /* partners, */
  } = data

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  // const [logosRef, logosInView] = useInView({
  //   triggerOnce: true,
  // })

  return (
    <SectionLayout>
      <RichText render={content} />

      <motion.div
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <IconsList items={areas} />
      </motion.div>

      {/* <motion.div
        ref={logosRef}
        initial="initial"
        animate={logosInView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <LogosWrapper>
          <Subtitle>Core team</Subtitle>
          <LogosList>
            {partners.map(partner => {
              const { logo, logoSharp, title } = partner

              let width, height
              if (logoSharp && logoSharp.childImageSharp) {
                width = logoSharp.childImageSharp.fixed.width
                height = logoSharp.childImageSharp.fixed.height
              }

              return (
                <Logo key={title} style={{ width, height }}>
                  {logoSharp && logoSharp.childImageSharp ? (
                    <Img
                      fixed={logoSharp.childImageSharp.fixed}
                      title={title}
                    />
                  ) : (
                    <img src={logo.url} alt="" title={title} />
                  )}
                </Logo>
              )
            })}
          </LogosList>
        </LogosWrapper>
      </motion.div> */}
    </SectionLayout>
  )
}
