import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { motion } from "framer-motion"
import { listReset } from "../reset"
import { rem } from "../utils"
import theme from "../theme"
import { useMotionDelay } from "../hooks"

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
  }
`

export const FRAGMENT_Map_section = graphql`
  fragment FRAGMENT_Map_section on PRISMIC_Map_section {
    _meta {
      uid
    }

    map_desktop
    map_desktopSharp {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      publicURL
    }

    map_mobile
    map_mobileSharp {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      publicURL
    }

    partners {
      country
      name
    }

    theme
  }
`

const Map = styled.div`
  max-width: ${rem(900)};
  margin-top: ${rem(-120)};

  img {
    will-change: opacity;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-top: ${rem(-180)};
  }
`

const CountriesList = styled.ul`
  ${listReset};

  margin-top: ${rem(32)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    columns: 2;
    margin-top: ${rem(48)};
  }

  @media (min-width: ${rem(theme.screens.md)}) {
    columns: 3;
  }
`

const CountryItem = styled.li`
  display: flex;
  margin-bottom: ${rem(8)};

  span {
    color: #c8102e;
    display: inline-block;
    font-size: ${rem(16)};
    font-weight: 700;
    line-height: 1.35;
  }

  p {
    color: #000;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.35;
  }

  > div:first-child {
    flex: 0;
    margin-right: ${rem(16)};
    min-width: ${rem(24)};
  }

  > div:nth-child(2) {
    flex: 1;
  }

  > div > p + p {
    margin-top: ${rem(8)};
  }
`

export default function MapSection({ data }) {
  const { map_desktopSharp, map_mobileSharp, partners } = data

  const partnersObj = React.useMemo(() => {
    const result = {}
    partners.forEach(partner => {
      const { country, name } = partner
      const list = result[country] || []
      result[country] = [...list, name]
    })
    return result
  }, [partners])

  let widerThanSmallScreen =
    typeof window !== "undefined" &&
    window.matchMedia(`(min-width: ${rem(theme.screens.sm)})`)
  widerThanSmallScreen = widerThanSmallScreen && widerThanSmallScreen.matches

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <motion.div
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <Map>
          <Img
            fluid={
              widerThanSmallScreen
                ? map_desktopSharp.childImageSharp.fluid
                : map_mobileSharp.childImageSharp.fluid
            }
          />
        </Map>

        <CountriesList>
          {Object.keys(partnersObj).map(country => {
            const names = partnersObj[country]
            return (
              <CountryItem key={country}>
                <div>
                  <span>{country}</span>
                </div>
                <div>
                  {names.map((name, index) => (
                    <p key={`${country}_${index + 1}`}>{name}</p>
                  ))}
                </div>
              </CountryItem>
            )
          })}
        </CountriesList>
      </motion.div>
    </SectionLayout>
  )
}
