import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import Button from "../Button"
import IntroCards from "../IntroCards"

export const FRAGMENT_Intro_section = graphql`
  fragment FRAGMENT_Intro_section on PRISMIC_Intro_section {
    _meta {
      uid
    }

    content

    image
    imageSharp {
      publicURL
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    video {
      ... on PRISMIC__FileLink {
        url
      }
    }

    body {
      ... on PRISMIC_Intro_sectionBodyCards {
        primary {
          card_image
          card_imageSharp {
            publicURL
            childImageSharp {
              fluid(quality: 90, maxWidth: 540) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                presentationWidth
              }
            }
          }
          title
          description
        }
      }
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: ${rem(48)} ${rem(24)} ${rem(96)};

  display: flex;
  flex-direction: column;
  min-height: calc(100vh + ${rem(70)});
  overflow-x: hidden;

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;
    align-items: center;
    min-height: 100vh;

    padding: ${rem(96)} ${rem(24)} ${rem(120)};
  }
`

const ContentGroup = styled.div`
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 1px ${({ theme }) => theme.background};

  button {
    width: 100%;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    button {
      width: auto;
    }
  }
`

const MediaGroup = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    will-change: opacity;
  }

  > div {
    height: 100%;
  }

  .video-wrapper {
    video {
      position: absolute;
      top: 0;
      left: 0;

      /* width: 100%;
      height: 100%;
      object-fit: cover; */

      /* Risina iOS object-fit problēmu */
      background-size: cover;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;

    > div {
      height: 100%;
      padding-bottom: 0 !important;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      100deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.6) 60%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`

const mediaVariants = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      delay: 1.75,
      type: "spring",
      damping: 250,
      stiffness: 25,
    },
  },
}

export default function IntroSection({ data }) {
  const { content, image, imageSharp, video, body } = data

  let widerThanSmallScreen =
    typeof window !== "undefined" &&
    window.matchMedia(`(min-width: ${rem(theme.screens.sm)})`)
  widerThanSmallScreen = widerThanSmallScreen && widerThanSmallScreen.matches

  return (
    <>
      <SectionLayout>
        <MediaGroup>
          <motion.div
            initial="initial"
            animate="enter"
            variants={mediaVariants}
          >
            {video && widerThanSmallScreen ? (
              <div className="video-wrapper">
                <video
                  loop
                  muted
                  autoPlay
                  playsInline
                  disablePictureInPicture
                  poster={
                    imageSharp ? imageSharp.publicURL : image && image.url
                  }
                >
                  <source src={video.url} type="video/mp4" />
                </video>
              </div>
            ) : imageSharp && imageSharp.childImageSharp ? (
              <Img fluid={imageSharp.childImageSharp.fluid} loading="eager" />
            ) : image ? (
              <img src={image.url} alt="" />
            ) : null}
          </motion.div>
        </MediaGroup>

        <ContentGroup>
          <RichText render={content} wide />
          <motion.div
            initial="initial"
            animate="enter"
            variants={theme.motion.contentVariants}
            custom={{ delay: 1.5 }}
          >
            <Button
              onClick={() => {
                const uid = "contacts"
                const section = document.body.querySelector(
                  `section[data-key="${uid}"]`
                )
                if (section) section.scrollIntoView()
              }}
            >
              Get in touch
            </Button>
          </motion.div>
        </ContentGroup>
      </SectionLayout>

      <IntroCards data={body} />
    </>
  )
}
