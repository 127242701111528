import React from "react"
import styled from "styled-components"

const TypePath = styled.path`
  fill: ${({ theme }) => theme.logo};
`

export default React.memo(function Logo() {
  return (
    <svg
      width="116"
      height="50"
      viewBox="0 0 116 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <TypePath d="M7.4359 26.2117L10.2809 30.4208C10.9922 31.4412 9.95764 32.5254 9.0524 32.5254C8.53512 32.5254 8.0825 32.2703 7.82386 31.8877L4.33222 26.5305H2.9097V31.1861C2.9097 31.8877 2.19844 32.5254 1.42252 32.5254C0.71126 32.5254 0 31.8877 0 31.1861V19.4515C0 18.6861 0.71126 17.9846 1.55184 17.9846H5.1728C9.0524 17.9846 10.2809 20.153 10.2809 22.3214C10.2809 24.2984 9.18172 25.6377 7.4359 26.2117ZM5.10814 20.5994H2.9097V23.9795H5.23746C6.65998 23.9795 7.4359 23.4055 7.4359 22.3214C7.37124 21.4285 6.98328 20.5994 5.10814 20.5994Z" />
      <TypePath d="M14.8718 31.5689C14.6778 32.1429 14.0312 32.5255 13.5139 32.5255C12.738 32.5255 12.0268 32.0153 12.0268 31.25C12.0268 31.1225 12.0268 30.9312 12.0914 30.8036L16.1003 19.2602C16.359 18.4949 16.8116 17.8572 17.7815 17.8572C18.7514 17.8572 19.204 18.4312 19.4627 19.2602L23.4716 30.8036C23.5362 30.9312 23.5362 31.1225 23.5362 31.25C23.5362 32.0153 22.8896 32.5255 22.0491 32.5255C21.5318 32.5255 20.8852 32.1429 20.6265 31.5689L19.9153 29.5281H15.5184L14.8718 31.5689ZM18.1695 23.7245C17.9755 23.1505 17.9108 22.3852 17.7815 21.7475C17.7168 22.3852 17.5875 23.1505 17.3935 23.7245L16.2943 27.1684H19.204L18.1695 23.7245Z" />
      <TypePath d="M29.1617 32.3341H26.8339C25.9933 32.3341 25.2174 31.6326 25.2174 30.8673V19.4515C25.2174 18.6861 25.9287 17.9846 26.8339 17.9846H29.1617C33.8172 17.9846 35.4337 20.5356 35.4337 23.3418V26.7856C35.4337 29.6555 33.8818 32.3341 29.1617 32.3341ZM32.5886 23.3418C32.5886 21.8111 31.8127 20.5994 29.5496 20.5994H28.1271V29.7831H29.5496C31.8127 29.7831 32.5886 28.4438 32.5886 26.8494V23.3418Z" />
      <TypePath d="M37.5028 19.1327C37.5028 18.4312 38.1494 17.8572 38.9253 17.8572C39.7012 17.8572 40.3478 18.4949 40.3478 19.1327V31.25C40.3478 31.9516 39.7012 32.5255 38.9253 32.5255C38.1494 32.5255 37.5028 31.8878 37.5028 31.25V19.1327Z" />
      <TypePath d="M42.6109 27.1684V23.1505C42.6109 20.2806 44.0981 17.8572 47.9777 17.8572C51.8573 17.8572 53.3445 20.2806 53.3445 23.1505V27.1684C53.3445 30.1659 51.534 32.5255 47.9777 32.5255C44.4214 32.5255 42.6109 30.1021 42.6109 27.1684ZM45.5206 23.1505V27.1684C45.5206 28.7628 46.2965 29.9108 47.9777 29.9108C49.6588 29.9108 50.4348 28.7628 50.4348 27.1684V23.1505C50.4348 21.4924 49.7882 20.4082 47.9777 20.4082C46.1672 20.4082 45.5206 21.4924 45.5206 23.1505Z" />
      <TypePath d="M103.65 32.3341H96.8606C96.02 32.3341 95.3734 31.7602 95.3734 30.9948C95.3734 30.7397 95.3734 30.3571 95.5674 30.0382C96.408 28.2525 97.8952 26.977 99.3824 25.6377C100.546 24.6173 102.163 23.4693 102.163 21.9387C102.163 20.9183 101.516 20.0892 100.223 20.0892C99.5117 20.0892 98.9944 20.2168 98.4771 20.4081C98.0245 20.5994 97.6365 20.9821 96.9899 20.9821C96.2787 20.9821 95.5674 20.4719 95.5674 19.7704C95.5674 18.3035 98.5418 17.6658 100.158 17.6658C103.197 17.6658 104.943 19.5153 104.943 21.8112C104.943 23.5331 104.232 24.6811 103.327 25.6377C101.969 27.0408 100.158 28.3163 98.9944 29.8469H103.585C104.361 29.8469 105.008 30.4209 105.008 31.1224C105.072 31.7602 104.426 32.3341 103.65 32.3341Z" />
      <TypePath d="M112.185 26.7856C111.732 26.5942 111.215 26.4029 110.698 26.2116C108.693 25.5738 106.495 24.9361 106.495 22.0024C106.495 19.3876 108.37 17.7932 111.28 17.7932C112.508 17.7932 115.418 18.1121 115.418 19.6427C115.418 20.3442 114.901 20.9182 113.996 20.9182C113.608 20.9182 113.349 20.7907 112.961 20.6631C112.573 20.5356 112.12 20.3442 111.409 20.3442C110.375 20.3442 109.469 20.6631 109.469 21.8748C109.469 22.7677 109.987 23.0866 110.31 23.2141C110.827 23.4692 111.344 23.6606 111.797 23.7881C113.802 24.4259 116 25.1274 116 27.9973C116 30.8034 113.802 32.4616 110.892 32.4616C109.34 32.4616 106.495 31.9514 106.495 30.4845C106.495 29.8468 107.077 29.209 107.853 29.209C108.176 29.209 108.499 29.3366 108.887 29.4641C109.405 29.6555 110.051 29.8468 110.957 29.8468C112.379 29.8468 113.09 29.209 113.09 28.1248C113.026 27.232 112.573 26.9769 112.185 26.7856Z" />
      <path
        d="M58.194 32.5892C57.4827 32.5892 56.8361 32.0152 56.8361 31.2499V18.8137C56.8361 18.1121 57.4181 17.4744 58.194 17.4744C58.9052 17.4744 59.5518 18.0483 59.5518 18.8137V31.2499C59.5518 31.9514 58.9052 32.5892 58.194 32.5892Z"
        fill="#EF0018"
      />
      <path
        d="M62.8495 32.5892C62.1382 32.5892 61.4916 32.0152 61.4916 31.2499V18.8137C61.4916 18.1121 62.0735 17.4744 62.8495 17.4744C63.6254 17.4744 64.2073 18.0483 64.2073 18.8137V31.2499C64.2073 31.9514 63.6254 32.5892 62.8495 32.5892Z"
        fill="#EF0018"
      />
      <path
        d="M67.5697 34.1835C66.8584 34.1835 66.2118 33.6095 66.2118 32.8442V17.1554C66.2118 16.4539 66.7938 15.8162 67.5697 15.8162C68.3456 15.8162 68.9276 16.3901 68.9276 17.1554V32.908C68.9276 33.6095 68.2809 34.1835 67.5697 34.1835Z"
        fill="#EF0018"
      />
      <path
        d="M72.2252 39.0943C71.5139 39.0943 70.8673 38.5203 70.8673 37.755V12.2448C70.8673 11.5433 71.4493 10.9055 72.2252 10.9055C73.0011 10.9055 73.5831 11.4795 73.5831 12.2448V37.755C73.5831 38.5203 73.0011 39.0943 72.2252 39.0943Z"
        fill="#EF0018"
      />
      <path
        d="M76.9454 50C76.2341 50 75.5875 49.426 75.5875 48.6607V1.33929C75.5875 0.637755 76.1695 0 76.9454 0C77.7213 0 78.3032 0.573979 78.3032 1.33929V48.7245C78.2386 49.426 77.6566 50 76.9454 50Z"
        fill="#EF0018"
      />
      <path
        d="M81.6009 36.4159C80.8896 36.4159 80.243 35.8419 80.243 35.0766V14.9235C80.243 14.222 80.825 13.5842 81.6009 13.5842C82.3768 13.5842 82.9587 14.1582 82.9587 14.9235V35.1403C82.9587 35.8419 82.3121 36.4159 81.6009 36.4159Z"
        fill="#EF0018"
      />
      <path
        d="M86.2564 32.5892C85.5451 32.5892 84.8985 32.0152 84.8985 31.2499V18.8137C84.8985 18.1121 85.4805 17.4744 86.2564 17.4744C87.0323 17.4744 87.6143 18.0483 87.6143 18.8137V31.2499C87.6143 31.9514 87.0323 32.5892 86.2564 32.5892Z"
        fill="#EF0018"
      />
      <path
        d="M90.9766 32.5892C90.2653 32.5892 89.6187 32.0152 89.6187 31.2499V18.8137C89.6187 18.1121 90.2006 17.4744 90.9766 17.4744C91.7525 17.4744 92.3344 18.0483 92.3344 18.8137V31.2499C92.2698 31.9514 91.6878 32.5892 90.9766 32.5892Z"
        fill="#EF0018"
      />
      <TypePath d="M2.78038 40.1786C1.74582 39.6684 0 39.6684 0 37.8189C0 36.5434 0.84058 35.7781 2.13378 35.7781C2.65106 35.7781 3.94426 35.9056 3.94426 36.4796C3.94426 36.6709 3.81494 36.8623 3.49164 36.8623C3.16834 36.8623 2.9097 36.5434 2.19844 36.5434C1.55184 36.5434 0.90524 36.7985 0.90524 37.6913C0.90524 38.3929 1.2932 38.5842 1.48718 38.7118C2.52174 39.222 4.26756 39.222 4.26756 41.0714C4.26756 42.4745 3.29766 43.2398 1.9398 43.2398C1.2932 43.2398 0 43.0485 0 42.4745C0 42.2832 0.19398 42.0919 0.38796 42.0919C0.71126 42.0919 1.16388 42.4745 1.9398 42.4745C2.9097 42.4745 3.36232 42.0281 3.36232 41.199C3.36232 40.5612 3.03902 40.3061 2.78038 40.1786Z" />
      <TypePath d="M5.75477 36.7985C5.49613 36.7985 5.23749 36.5434 5.23749 36.2883C5.23749 36.0332 5.43147 35.7781 5.75477 35.7781C6.01341 35.7781 6.27205 35.9694 6.27205 36.2883C6.27205 36.5434 6.07807 36.7985 5.75477 36.7985ZM5.75477 43.3036C5.49613 43.3036 5.36681 43.1123 5.36681 42.9209V38.3291C5.36681 38.1378 5.56079 37.9464 5.75477 37.9464C6.01341 37.9464 6.14273 38.1378 6.14273 38.3291V42.9209C6.20739 43.1123 6.01341 43.3036 5.75477 43.3036Z" />
      <TypePath d="M10.6042 38.2017C10.8629 37.7552 11.1862 37.6277 11.4448 37.6277C11.6388 37.6277 11.8328 37.7552 11.8328 38.0103C11.8328 38.1379 11.7681 38.2017 11.7681 38.2017C11.3801 38.5843 11.1862 38.7119 11.1862 39.2221V39.8598C11.1862 40.944 10.5396 41.7093 9.31101 41.7093C8.98772 41.7093 8.72908 41.6455 8.47044 41.5818C8.40578 41.7731 8.34112 41.9644 8.34112 42.0282C8.34112 42.4108 8.66442 42.4746 8.92306 42.4746H9.37567C10.6042 42.4746 11.5095 42.9848 11.5095 44.0052C11.5095 45.0256 10.6042 45.7272 9.37567 45.7272C8.2118 45.7272 7.37122 45.1532 7.37122 44.1328C7.37122 43.6226 7.5652 43.2399 7.95316 42.8573C7.69452 42.6022 7.62986 42.2833 7.62986 42.0282C7.62986 41.7731 7.69452 41.4542 8.01782 41.1991C7.62986 40.8802 7.43588 40.37 7.43588 39.7323C7.43588 38.6481 8.08248 37.819 9.31101 37.819C9.69897 37.8828 10.2163 38.0103 10.6042 38.2017ZM8.47044 43.2399C8.2118 43.4313 8.08248 43.6864 8.08248 44.069C8.08248 44.7068 8.47044 45.0256 9.31101 45.0256C10.0869 45.0256 10.6042 44.7068 10.6042 44.1328C10.6042 43.5588 10.1516 43.3037 9.31101 43.3037H8.8584C8.72908 43.3037 8.59976 43.2399 8.47044 43.2399ZM10.4102 38.9032C10.0869 38.7119 9.76363 38.5843 9.37567 38.5843C8.59976 38.5843 8.2118 39.0307 8.2118 39.7961C8.2118 40.4976 8.5351 40.944 9.31101 40.944C10.0869 40.944 10.4102 40.4976 10.4102 39.8598V39.2221C10.3456 39.0945 10.4102 39.0307 10.4102 38.9032Z" />
      <TypePath d="M13.6432 42.921C13.6432 43.1123 13.5139 43.3036 13.2553 43.3036C12.9966 43.3036 12.8673 43.1123 12.8673 42.921V39.4133C12.8673 38.8393 12.8027 38.8393 12.6733 38.7118C12.6733 38.648 12.6087 38.5842 12.6087 38.5205C12.6087 38.3291 12.8027 38.1378 12.9966 38.1378C13.1906 38.1378 13.3199 38.2654 13.4493 38.4567C13.9019 38.1378 14.4192 38.0103 14.9364 38.0103C16.0357 38.0103 16.6176 38.7756 16.6176 39.9873V43.0485C16.6176 43.2398 16.4883 43.4312 16.2296 43.4312C15.971 43.4312 15.8417 43.2398 15.8417 43.0485V39.9873C15.8417 39.222 15.5184 38.7756 14.8718 38.7756C14.4838 38.7756 14.0312 38.9669 13.7079 39.222C13.7079 39.3495 13.7079 39.4133 13.7079 39.5409V42.921H13.6432Z" />
      <TypePath d="M20.7558 39.7323C20.7558 38.967 20.3679 38.6481 19.6566 38.6481C19.2687 38.6481 19.0747 38.7119 18.8807 38.8394C18.8807 38.8394 18.7514 38.9032 18.6221 38.9032C18.4281 38.9032 18.3634 38.7119 18.2988 38.5843C18.2341 38.1379 19.0747 37.8828 19.7859 37.8828C21.0791 37.8828 21.6611 38.5843 21.6611 39.9874V42.2833C21.6611 42.4746 21.7257 42.6022 21.8551 42.6022H21.9197C22.1137 42.6022 22.243 42.7935 22.243 42.9849C22.243 43.24 21.9844 43.3675 21.7904 43.3675C21.4671 43.3675 21.1438 43.1762 21.0145 42.8573C20.6265 43.24 20.1092 43.4313 19.592 43.4313C18.4927 43.4313 17.9755 42.7935 17.9755 41.8369V41.7731C17.9755 40.7527 18.5574 40.2425 19.8506 40.2425C20.2386 40.2425 20.5619 40.3063 20.8852 40.3701V39.7323H20.7558ZM18.6221 41.7093C18.6221 42.2195 18.816 42.6022 19.5273 42.6022C19.9799 42.6022 20.3679 42.4746 20.6912 42.1558V41.0078C20.3679 40.8803 19.9153 40.8803 19.592 40.8803C18.816 40.8165 18.6221 41.2629 18.6221 41.7093Z" />
      <TypePath d="M23.2129 36.097C23.2129 35.9057 23.4069 35.7144 23.6009 35.7144C23.8595 35.7144 23.9889 35.9057 23.9889 36.097V42.2195C23.9889 42.4746 23.9889 42.6021 24.1182 42.7297C24.1182 42.7934 24.1828 42.8572 24.1828 42.921C24.1828 43.1123 23.9889 43.3036 23.7949 43.3036C23.5362 43.3036 23.1483 43.1761 23.1483 42.2195V36.097H23.2129Z" />
      <TypePath d="M31.1661 40.1786C30.1962 39.6684 28.3857 39.6684 28.3857 37.8189C28.3857 36.5434 29.2263 35.7781 30.5195 35.7781C31.0368 35.7781 32.33 35.9056 32.33 36.4796C32.33 36.6709 32.2007 36.8623 31.8774 36.8623C31.5541 36.8623 31.2954 36.5434 30.5842 36.5434C29.9376 36.5434 29.291 36.7985 29.291 37.6913C29.291 38.3929 29.6789 38.5842 29.8729 38.7118C30.9075 39.222 32.6533 39.222 32.6533 41.0714C32.6533 42.4745 31.6834 43.2398 30.3255 43.2398C29.6789 43.2398 28.3857 43.0485 28.3857 42.4745C28.3857 42.2832 28.5797 42.0919 28.7737 42.0919C29.097 42.0919 29.5496 42.4745 30.3255 42.4745C31.2954 42.4745 31.748 42.0281 31.748 41.199C31.748 40.5612 31.4247 40.3061 31.1661 40.1786Z" />
      <TypePath d="M36.6622 42.2833C36.6622 42.2833 36.7269 42.2833 36.7915 42.2833C36.9855 42.2833 37.1795 42.4746 37.1795 42.6659C37.1795 43.1761 36.0156 43.3675 35.4983 43.3675C34.3345 43.3675 33.6232 42.6021 33.6232 41.6455V39.7322C33.6232 38.7118 34.2698 37.9465 35.563 37.9465C36.7269 37.9465 37.3088 38.5843 37.3088 39.6685C37.3088 40.6251 36.7915 41.1991 35.4983 41.1991C35.175 41.1991 34.7871 41.1991 34.3991 41.1353V41.7731C34.3991 42.347 34.7871 42.7297 35.563 42.7297C36.0156 42.6021 36.2743 42.4746 36.6622 42.2833ZM35.4983 38.6481C34.7871 38.6481 34.3991 39.0307 34.3991 39.6047V40.37C34.7871 40.4338 35.3044 40.4338 35.4983 40.4338C36.2743 40.4338 36.4682 39.9873 36.4682 39.6047C36.4682 38.9032 36.0803 38.6481 35.4983 38.6481Z" />
      <TypePath d="M39.3133 42.921C39.3133 43.1123 39.184 43.3036 38.9253 43.3036C38.6667 43.3036 38.5374 43.1123 38.5374 42.921V39.4133C38.5374 38.8393 38.4727 38.8393 38.3434 38.7118C38.3434 38.648 38.2787 38.5842 38.2787 38.5205C38.2787 38.3291 38.4727 38.1378 38.6667 38.1378C38.8607 38.1378 38.99 38.2654 39.1193 38.4567C39.5719 38.1378 40.0892 38.0103 40.6065 38.0103C41.7057 38.0103 42.2876 38.7756 42.2876 39.9873V43.0485C42.2876 43.2398 42.1583 43.4312 41.8997 43.4312C41.641 43.4312 41.5117 43.2398 41.5117 43.0485V39.9873C41.5117 39.222 41.1884 38.7756 40.5418 38.7756C40.1539 38.7756 39.7012 38.9669 39.3779 39.222C39.3779 39.3495 39.3779 39.4133 39.3779 39.5409V42.921H39.3133Z" />
      <TypePath d="M45.3267 37.8828C45.844 37.8828 46.7492 38.0104 46.7492 38.4568C46.7492 38.6481 46.6199 38.8394 46.3612 38.8394C46.2966 38.8394 46.2319 38.8394 46.1673 38.7757C45.9733 38.6481 45.7146 38.5843 45.3913 38.5843C44.7447 38.5843 44.4861 38.8394 44.4861 39.2859C44.4861 39.6047 44.6154 39.7961 45.0034 39.9236C45.7146 40.3063 46.9432 40.3701 46.9432 41.7093C46.9432 42.666 46.3612 43.2399 45.1974 43.2399C44.6801 43.2399 43.6455 43.1124 43.6455 42.666C43.6455 42.4746 43.7748 42.2833 44.0335 42.2833C44.0981 42.2833 44.0981 42.2833 44.1628 42.2833C44.4861 42.4109 44.8094 42.5384 45.1974 42.5384C45.7793 42.5384 46.1673 42.2833 46.1673 41.7731C46.1673 41.3905 45.9733 41.2629 45.7793 41.1354C45.1327 40.7527 43.7102 40.6889 43.7102 39.3496C43.5808 38.4568 44.2274 37.8828 45.3267 37.8828Z" />
      <TypePath d="M50.8874 42.2833C50.8874 42.2833 50.9521 42.2833 51.0167 42.2833C51.2107 42.2833 51.4047 42.4746 51.4047 42.6659C51.4047 43.1761 50.2408 43.3675 49.7235 43.3675C48.5596 43.3675 47.8484 42.6021 47.8484 41.6455V39.7322C47.8484 38.7118 48.495 37.9465 49.7882 37.9465C50.9521 37.9465 51.534 38.5843 51.534 39.6685C51.534 40.6251 51.0167 41.1991 49.7235 41.1991C49.4002 41.1991 49.0123 41.1991 48.6243 41.1353V41.7731C48.6243 42.347 49.0123 42.7297 49.7882 42.7297C50.2408 42.6021 50.4994 42.4746 50.8874 42.2833ZM49.7235 38.6481C49.0123 38.6481 48.6243 39.0307 48.6243 39.6047V40.37C49.0123 40.4338 49.5295 40.4338 49.7235 40.4338C50.4994 40.4338 50.6934 39.9873 50.6934 39.6047C50.6934 38.9032 50.3055 38.6481 49.7235 38.6481Z" />
    </svg>
  )
})
