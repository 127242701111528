import React from "react"
import { graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import IconsList from "../IconsList"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_Project_section = graphql`
  fragment FRAGMENT_Project_section on PRISMIC_Scope_section {
    _meta {
      uid
    }

    content

    functions {
      icon
      iconSharp {
        childImageSharp {
          fixed(quality: 90, width: 58, height: 58) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        publicURL
      }
      description
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
  }
`

export default function ProjectSection({ data }) {
  const { content, functions } = data

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <RichText render={content} wide />

      <motion.div
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <IconsList items={functions} />
      </motion.div>
    </SectionLayout>
  )
}
