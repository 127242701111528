import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { listReset } from "./reset"
import { rem, fluidValue } from "./utils"
import theme from "./theme"
import Card from "./Card"

const asideWidth = 180
const minAsideWidth = 150

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  transform: translateY(calc(100% - ${rem(40)}));

  margin: 0 auto;
  max-width: ${rem(1080)};
  overflow: hidden;

  @media (min-width: ${rem(theme.screens.sm)}) {
    left: ${fluidValue(
      minAsideWidth,
      asideWidth + 24,
      theme.screens.sm,
      theme.screens.lg
    )};
    padding-left: ${rem(24)};
    transform: translateY(calc(100% - ${rem(60)}));
  }

  @media (min-width: ${rem(theme.screens.lg)}) {
    left: ${rem(asideWidth + 24)};
  }
`

const CardsList = styled(motion.ul)`
  ${listReset};

  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 ${rem(24)};
  scroll-snap-type: x mandatory;

  > li + li {
    margin-left: ${rem(16)};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-wrap: wrap;
    margin: ${rem(-8)};
    overflow-x: auto;
    padding: 0;

    > li {
      margin: ${rem(8)};
    }
  }

  @media (min-width: ${rem(theme.screens.md)}) {
    margin: ${rem(-12)};

    > li {
      margin: ${rem(12)};
    }
  }
`

const listVariants = {
  enter: {
    transition: { staggerChildren: 0.2 },
  },
}

export default function IntroCards({ data }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })
  return (
    <Wrapper data-key="intro_cards">
      <CardsList ref={ref} variants={listVariants}>
        {data.map(({ primary }, i) => {
          const {
            card_image: image,
            vard_imageSharp: imageSharp,
            title,
            description,
          } = primary
          return (
            <Card
              key={`card_${i + 1}`}
              index={i}
              image={image}
              imageSharp={imageSharp}
              title={title}
              description={description}
              animate={inView}
            />
          )
        })}
      </CardsList>
    </Wrapper>
  )
}
